.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: inline-block;
  /* width: 80%; */
  /* height: 100%; */
  object-fit: cover;
}
.swiper-button-prev .swiper-button-next {
  /* color: black !important; */
  opacity: 80%;
  --swiper-theme-color: black !important;
}

#autoSwiper .swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
