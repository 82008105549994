@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"),
    url("../../Fonts/DINNextLTPro-Regular.ttf") format("ttf");
}

.App {
  text-align: center;
}

.parallax {
  height: 900px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 1s ease-in-out;
  position: relative;
}

@keyframes marquee {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
@media screen and (min-width: 600px) {
  .marquee {
    animation: marquee 10s linear infinite;
  }
  .parallax,
  .marquee {
    transition: opacity 0.5s ease-in-out;
  }
}
/* @media screen and (max-width: 500px) {
  .parallax {
    height: 215px !important;
    background-size: contain;
  }
} */
@media screen and (max-width: 500px) {
  .parallax {
    height: 186px;
    background-position: center right;
    background-attachment: inherit;
    
  }
}

@media (min-width: 510px) and (max-width: 840px) {
  .parallax {
    height: 550px;
    background-position: center right;
    background-attachment: inherit;
  }
}

@media (min-width: 850px) and (max-width: 1279px) {
  .parallax {
    background-size: contain;
    height: 535px;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .parallax {
    height: 615px;
    background-size: cover;
  }
}
@media screen and (min-width: 1441px) {
  .parallax {
    background-size: cover;
  }
}
.EnglishPage {
  font-family: DINNextLTPro-Regular;
}
